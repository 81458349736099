import React, { useRef, useState } from "react";
import useTranslation from "../hooks/useTranslation";
import { init } from "@emailjs/browser";
import emailjs from "@emailjs/browser";
import { Pocket } from "../commons/Icons/Icons";
import Header from "@components/Header";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

interface ViewProps {
  pathName: string;
}

interface FormInput {
  fromName: string;
  email: string;
  message: string;
}

interface ConfirmViewProps {
  text: string;
}

function ConfirmView(props: ConfirmViewProps) {
  return (
    <div className="flex justify-center h-full w-full pt-5">
      <div className="font-bold">{props.text}</div>
    </div>
  );
}

function ContactView(props: ViewProps) {
  const { t } = useTranslation(props.pathName);
  const typeOfPerson = "privat";
  const [status, setStatus] = useState<"loading" | "sent">("loading");
  const form = useRef<HTMLFormElement | null>(null);
  init("qsoSQtlM6Axqe-U2Z");

  const sendEmail = async (event: any) => {
    event.preventDefault();
    if (!form.current) {
      console.error("Something must have gone wrong...");
      return false;
    }

    const validateInput = (input: FormInput) => {
      const errors: string[] = [];
      if (!input.email) errors.push("Provide an email!");
      if (errors.length === 0) return null;
      return errors;
    };

    const values = {
      email: form.current.email.value,
      fromName: form.current.fromName.value,
      typeOfPerson: typeOfPerson,
      message: "En användare har begärt att sin data bör raderas!",
    };
    console.log(values);
    const errors = validateInput(values);

    if (errors !== null) {
      alert(errors.join("\n"));
      return;
    }

    try {
      await emailjs.send("service_k88bifl", "template_qrwt2n9", values);
      setStatus("sent");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {status == "loading" && (
        <Element name="contact">
          <div
            style={{
              height: "75vh",
              backgroundPosition: "fit",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className="sm:w-5/6 m-auto flex justify-center">
              <div className="flex sm:w-1/2 w-full px-10 flex-col mt-5">
                <div className="mb-5 sm:pt-40">
                  <Header
                    text={t(
                      "Enter your e-mail address in the field below to request to have your personal data deleted. Enter the email address you used when you registered as a user."
                    )}
                  ></Header>
                </div>

                <form className="w-full" ref={form} onSubmit={sendEmail}>
                  <div className="mb-4">
                    <label className=" text-gray-700 text-sm font-bold mb-2">
                      {t("Name")}
                    </label>
                    <input
                      className="bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                      id="fromName"
                      type="text"
                      placeholder={t("Name")}
                    />
                  </div>
                  <div className="mb-4">
                    <label className=" text-gray-700 text-sm font-bold mb-2">
                      {t("Email")}
                    </label>
                    <input
                      className=" border rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                      id="email"
                      type="text"
                      placeholder={t("Email")}
                    />
                  </div>
                  <button
                    style={{ color: "whitesmoke", background: "#697291" }}
                    className="p-5 w-full flex items-stretch  rounded-md justify-center"
                    formAction="submit"
                    name="submit-contact-form-button"
                  >
                    {t("Send")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </Element>
      )}
      {status == "sent" && (
        <ConfirmView
          text={t(
            "Thank you for your request to have your personal data deleted. If we can match the provided email address to our user register, we will reply to the provided email address for a confirmation that you need to respond to in order to have your data deleted."
          )}
        ></ConfirmView>
      )}
    </>
  );
}

export default ContactView;
