import { useEffect } from "react";
import * as React from "react";

import RemoveDataView from "../views/RemoveDataView";
import Topbar from "../components/Topbar";
import FootView from "../views/FootView";
import useTranslation from "../hooks/useTranslation";

const RemoveDataPage = (props: any) => {
  useEffect(() => {
    window.location.href = "https://www.saluto.ai";
  }, []);

  return <></>;

  return (
    <>
      <Topbar pathName={props.location.pathname}></Topbar>

      <RemoveDataView pathName={props.location.pathname}></RemoveDataView>
      <FootView pathName={props.location.pathname}></FootView>
    </>
  );
};

export default RemoveDataPage;
